(function($){
    
    /*
     *
     * Equal Height jQuery Snippet.  Using this for footer and grid accordion
     *
     */
    var equalheight = function (container){

        var currentTallest = 0,
            currentRowStart = 0,
            rowDivs = [],
            $el,
            topPosition = 0,
            currentDiv;
    
        $(container).each(function () {

            $el = $(this);
            $el.height('auto');
            topPosition = $el.position().top;

            if (currentRowStart < topPosition - 1 || currentRowStart > topPosition + 1) {
                for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                    rowDivs[currentDiv].height(currentTallest);
                }
                rowDivs = [];
                currentRowStart = topPosition;
                currentTallest = $el.height();
                rowDivs.push($el);
            }
            else {
                rowDivs.push($el);
                currentTallest = Math.max(currentTallest, $el.height());
            }
        });
        
        for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
            rowDivs[currentDiv].css('height', currentTallest);
        }
    };
    
    $(document).ready(function() { 
        equalheight('.card--eheight');
    });
    
    $(window).on("resize", function() {
        equalheight('.card--eheight');
    });
})(jQuery);
(function($) {

	function downloadFilterToggle(){
		var filterGroup = $('.post_filtering_filter'),
            filterPanel = $('.filter-checkbox-panel');
        
        //$('.filter-checkbox-panel').hide().first().show();
        $('.filter-checkbox-panel').hide();
        
        //$('.filter-checkbox-trigger').first().addClass('active');
        
        $('.filter-checkbox-trigger').on('click', function() {
            var panelHeight = $(this).siblings('.filter-checkbox-panel').height();

            if (!$(this).hasClass("active")) {
            	$('.filter-checkbox-trigger').removeClass('active');
            	$(this).addClass('active');
            	$('.filter-checkbox-panel').hide();
            
	            if($(window).width() > 768) {
	                $('.post_filtering_filter').height('auto');
	                $(this).closest('.post_filtering_filter').height(panelHeight);
	            }
	            
	            $(this).siblings('.filter-checkbox-panel').show();

            }else{
            	$(this).removeClass('active');
            	$('.post_filtering_filter').height('auto')
            	$('.filter-checkbox-panel').hide();
            }
          
        });
	}

	function shareModal(){
		//Share Modal
		$('.share-modal').on('click', function(e) {
			e.preventDefault();
			var data = {
				'action': "cmp_share_modal",
				'postid': $(this).data('postid')
			};
			console.log(data);
			$.colorbox({
				open: true
			});
			$.ajax({
				url: window.CMPData.ajax_url,
				type: "POST",
				data: data,
				success: function(data, textStatus, jqXHR) {
					$.colorbox({
						html: data.content
					});
				},
				error: function(jqXHR, textStatus, errorThrown) {
					console.log(jqXHR);
					console.log(textStatus);
					console.log(errorThrown);
				}
			});

			/*$.colorbox({
				open: true,
				html: $wrap_start + $html + $custom + $share + $wrap_end
			});*/
		});
	}

	$(document).ready(function() {
        
        $('.card__dropdown--title').click(function(e) {
            e.preventDefault();
            $(this).toggleClass('active').next().slideToggle('fast');
        });
        
        downloadFilterToggle();
        
        $('.filter-checkbox-panel input[type="checkbox"]').on('change',function(){
            $('.post_filtering_form').submit();
        });
        
        $('#filterSearch').on('click',function(){
            $('.post_filtering_form').submit();
        });

		$('.gallery-item').find('a').colorbox({
			'maxWidth': '90%',
			'maxHeight': '90%',
			'scalePhotos': true
		});

		$('a.colorbox').colorbox({
			opacity: 0.5,
			rel: 'group1',
			'maxWidth': '90%',
			'maxHeight': '90%',
			'scalePhotos': true
		});

		$('.splash-navigation-wrap').find('.mobile-dropdown-toggle a').on('click', function(e) {
			e.preventDefault();
			$('.splash-navigation').slideToggle();
		});

		$('.product-navigation-wrap').find('.mobile-dropdown-toggle a').on('click', function(e) {
			e.preventDefault();
			$('.product-navigation').slideToggle();
		});

		$('.js-select-target').change(function() {
			var value = $(this).val();
			if ('' == value) {
				return;
			}
			window.location = value;
		});

		var $collection_products = $('.collection_products');
		$collection_products.find('.collection_product').click(function(e) {
			e.preventDefault();
			$collection_products.find('.active').removeClass('active');
			$(this).addClass('active');
			$right_side = $collection_products.find('.right_side');

			$right_side.find('.title').html($(this).attr('title'));
			$right_side.find('.content').html($(this).find('span').text());
			$right_side.find('.button').attr({
				'href': $(this).attr('href')
			});
		});

		// Start Dropdown Menu
		$(".nav-header .menu li").dropdown();

		// Detect external links and add appropriate class
		$('a').filter(function() {
			return this.hostname && this.hostname !== location.hostname;
		}).addClass("external").attr("target", "_blank");

		$("#MobileMenu").click(function(e) {
			e.preventDefault();
			$('#SiteHeader').toggleClass('mobile-menu-active');
		});

		var $SiteHeader = $('#SiteHeader');
		$(window).on('resize', function() {
			if (
				parseInt($(window).width()) > 1180 &&
				$SiteHeader.hasClass('mobile-menu-active')
			) {
				$SiteHeader.removeClass('mobile-menu-active');
			}
		});

		// Gravity Forms disabled / readonly inputs
		$(".gform_wrapper").find(".disabled").attr('disabled', 'disabled');
		$(".gform_wrapper").find(".readonly").attr('readonly', 'readonly');
		$("#NewsletterSignup").find("input[type='email']").attr('placeholder', 'NAME@DOMAIN.COM');

		//General use slider
		var splashSlider = $('.flexslider--splash');

		splashSlider.each(function() {
			/*var total = $(this).find('ul.slides li').length;

			//console.log(total);

			if (total > 1) {
				var showNav = true;
			} else {
				var showNav = false;
			}*/

			$(this).flexslider({
				pauseOnHover: true,
				pauseOnAction: true,
				smoothHeight: true,
				useCSS: true,
				//controlNav: true,
				//directionNav: true,
			});
		});


		$('#ProductSplashSlider').flexslider({
			pauseOnHover: true,
			pauseOnAction: true,
			animation: 'slide',
			smoothHeight: true,
			useCSS: false,
			after: function(slider) {
				$(slider.slides[slider.currentSlide]).find('.slide-image').each(function() {
					$(this).hotspots('_resize');
				});
			}
		});

		$('#ProductSplashSlider').find('.slide-image').hotspots();
		$('#ProductPageHotspots').find('.slide-image').hotspots();

		$('.product_banner--hotspot').find('.slide-image').hotspots();

		var brand_container = $('.brands-container').addClass('js-enabled');
		var brand_content_areas = brand_container.find('.brands-content').hide();
		var brand_links = brand_container.find('.links a');
		brand_links.click(function(e) {
			e.preventDefault();
			brand_links.removeClass('active');
			brand_content_areas.hide();

			var target = $(this).attr('href');
			$(target).show();
			$(this).addClass('active');
		}).first().click();

		$('.video-modal').on('hidden', function() {
			var iframe = $(this).find('iframe')[0].contentWindow;
			iframe.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
		});

		$('.collection_page').find('.video').fitVids();

		/* Analytics Tracking */
		$('#gform_1').submit(function(e) {
			_gaq.push(['_trackEvent', 'Form', 'Submit', 'Newsletter']);
		});

		$('#SplashCatalog').find('.button').click(function(e) {
			_gaq.push(['_trackEvent', 'Download', 'PDF', $(this).data('catalog').toUpperCase() + ' Catalog']);
		});

		//Product Table Get Details Modal
		$('.product-modal').find('.button').on('click', function(e) {
			e.preventDefault();
			var data = {
					'action': "cmp_table_row_modal",
					'productID': $(this).data('productid')
				},
				$cb = $.colorbox({
					open: true,
				});

			$.ajax({
				url: window.CMPData.ajax_url,
				type: "POST",
				data: data,
				success: function(html, textStatus, jqXHR) {
					$.colorbox({
						html: html,
						maxWidth: '90%',
						maxHeight: '90%',
						fixed: true,
						onComplete: function() {
							$('.gallery').find('a').on('click', function(e) {
								e.preventDefault();
								var src = $(this).find('img').attr('src');
								$(this).parents('.product-img').find('.main-img').find('img').removeAttr('srcset').attr('src', src);
							});
							/**
								Note: colorbox was resizing before all images loaded
								and that was breaking the max height container. Set it
								to render below once images were fully loaded.
							**/
							//$(this).colorbox.resize();
							$('img').on('load', function() {
						    	$(this).colorbox.resize(); 
						    });
						}
					});
				},
				error: function(jqXHR, textStatus, errorThrown) {
					console.log(jqXHR);
					console.log(textStatus);
					console.log(errorThrown);
				}
			});
		});

		// Product Table List toggle
		var $list_toggles = $('.list_toggle').find('a');
		$list_toggles.on('click', function(e) {
			e.preventDefault();
			var $type = $(this).data('type'),
				$product_list = $('.product-list');
			Cookies.set('cmp_list_type', $type);

			if ($type == 'grid') {
				$product_list.addClass('grid-view');
			} else {
				$product_list.removeClass('grid-view');
			}

			$list_toggles.removeClass('active');
			$(this).addClass('active');
		});

		//Video Modals
		$('.video-modal').colorbox({
			iframe: true,
			innerWidth: 640,
			innerHeight: 390
		});

		$('.watch-video').colorbox({
			iframe: true,
			innerWidth: 640,
			innerHeight: 390
		});

	
		shareModal();
		$( document ).ajaxComplete(function() {
            shareModal();
        });



		$('.copyOnClick').on('click', function(e) {
			console.log('click');
			$(this)[0].select();
			document.execCommand('copy');
		});

		var $splash_navigation_wrap = $('.splash-navigation-wrap');
		if (
			$splash_navigation_wrap.length &&
			!$splash_navigation_wrap.hasClass('blog-list-categories')
		) {
			var $tab_nav_offset = $SiteHeader.height(),
				$splash_nav_top = $splash_navigation_wrap.offset().top - $tab_nav_offset;
			$(window).on('scroll', function() {
				var $push = ($("#hellobar-pusher").height()) ? 41 : 0; //Hello bar offset
				if ($(window).scrollTop() >= $splash_nav_top) {
					$splash_navigation_wrap.css({
						'position': 'fixed',
						'top': $tab_nav_offset + $push
					})
				} else {
					$splash_navigation_wrap.css({
						position: 'static'
					});
				}
			});
		}
	});

	$( window ).resize(function() {
        var panelHeight = $('.filter-checkbox-trigger.active').siblings('.filter-checkbox-panel').height();

        if ($('.filter-checkbox-trigger.active').length) {
            if($(window).width() > 768) {
                $('.post_filtering_filter').height('auto');
                $('.filter-checkbox-trigger.active').closest('.post_filtering_filter').height(panelHeight);
            }else{
            	$('.post_filtering_filter').height('auto');
            }
        }
	});

	$(window).load(function() {
		if ($(window).width() >= 768) {
			var $collection_items = $('.collection-tile'),
				$tallest = 0;
			$collection_items.each(function() {
				if (parseInt($(this).outerHeight()) > $tallest) {
					$tallest = parseInt($(this).outerHeight());
				}
				var $height = $(this).height();
			}).css({
				'height': $tallest
			});
		}
	});
})(jQuery);

function loadRequestForm($productid) {
	var data = {
		'action': "cmp_request_product_information_form",
		'productID': $productid
	};
	console.log(data);
	$.ajax({
		url: window.CMPData.ajax_url,
		type: "POST",
		data: data,
		success: function(html, textStatus, jqXHR) {
			$.colorbox({
				html: html
			});
		},
		error: function(jqXHR, textStatus, errorThrown) {
			console.log(jqXHR);
			console.log(textStatus);
			console.log(errorThrown);
		}
	});
}

;( function( $, window, document, undefined ) {

	"use strict";

	var pluginName = "CMPPanels",
		defaults = {
			panelSelector: '.panel',
			linkSelector: '.panel-link'
		};

	function CMPPanels ( element, options ) {
		var self = this;
		self.element = element;

		self.settings = $.extend( {}, defaults, options );

		self.init();
	}

	$.extend( CMPPanels.prototype, {

		init: function() {
			var self = this;
			self.panels = $(self.element).find( self.settings.panelSelector );

			self.panels.not(":eq(0)").hide();

			$(self.element).on('click', self.settings.linkSelector, function( e ){
				e.preventDefault();
				self.panels.hide();
				$($(this).attr("href")).show();
			});

		}

	} );

	// A really lightweight plugin wrapper around the constructor,
	// preventing against multiple instantiations
	$.fn[ pluginName ] = function( options ) {
		return this.each( function() {
			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" +
					pluginName, new CMPPanels( this, options ) );
			}
		} );
	};

	$('.js-panels').CMPPanels();

} )( jQuery, window, document );